import React from "react"
import classes from "./title.module.css"

const Title = () => {
  return (
    <div>
      <h1 className={classes.trailsText + " " + classes.title}>しょうのまき</h1>
      <div className={classes.title2}>
        イラストレーター &amp; グラフィックデザイナー
      </div>
    </div>
  )
}
export default Title
