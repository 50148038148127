import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import classes from "./index.module.css"
import Img from "gatsby-image"
import Title from "../components/title/title"
import "../utils/normalize.css"
import "../utils/css/screen.css"
import LinesEllipsis from "react-lines-ellipsis"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const Index = ({ data }) => {
  const posts = data.allContentfulTop.edges[0].node.post

  useEffect(() => {
    gsap.fromTo(".page-head", { y: 20, opacity: 0 }, { y: 0, opacity: 1 })
    gsap.fromTo(
      ".col-3",
      { y: 20, opacity: 0 },
      { y: 0, opacity: 1, delay: 0.3 }
    )
    gsap.fromTo(
      ".blog",
      { y: 20, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: ".blog", // start the animation when ".box" enters the viewport (once)
      }
    )
    gsap.fromTo(
      ".more",
      { y: 20, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: ".more", // start the animation when ".box" enters the viewport (once)
      }
    )
  }, [])

  const {
    allContentfulBlogPost: { edges: blogs },
  } = data
  return (
    <Layout>
      <SEO
        title="トップ"
        description="東京でイラストデザイン制作やロゴデザイン、チラシ制作、フライヤー制作、パンフレットの制作、カード類の制作などの広告印刷物の制作などをおこなっているしょうのまきのウェブサイトです。"
        keywords={[
          `イラスト制作`,
          `美容室の広告印刷物の制作`,
          `ロゴデザイン`,
          `チラシ制作`,
        ]}
      />

      <header className="page-head">
        <Title />
      </header>

      <article className="post-content">
        <div className="row no-gutter">
          {posts.map(b => (
            <div key={b.id} className="col-3 post-archive">
              <Link
                className="post-archive-link"
                to={`/${b.sys.contentType.sys.contentful_id}/${b.slug}`}
              >
                {b.heroImage ? (
                  <Img
                    className="kg-image"
                    fluid={b.heroImage ? b.heroImage.fluid : null}
                    alt={b.title}
                  />
                ) : (
                  <Img
                    className="kg-image"
                    fluid={data.file.childImageSharp.fluid}
                    alt="しょうののまきの制作事例"
                  />
                )}

                <div className="post-archive-content">
                  <h2 className="post-archive-title">
                    {b.title} <span>...more</span>
                  </h2>
                </div>
              </Link>
            </div>
          ))}
        </div>

        <div className={`more ${classes.more}`}>
          <p>
            <Link to={`/illustration`}>イラストレーションをもっと見る</Link>
          </p>
          <p>
            <Link to={`/design`}>デザインをもっと見る</Link>
          </p>
        </div>
      </article>

      <article className={`blog ${classes.blog}`}>
        <div>
          <h2>Recent Blog</h2>
          {blogs.map(blog => (
            <Link
              to={`/blog/${blog.node.slug}`}
              key={blog.node.id}
              className={classes.link}
            >
              <p>{blog.node.publishDate}</p>
              <p>
                <LinesEllipsis
                  text={blog.node.title}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </p>
            </Link>
          ))}

          <div className={`${classes.more} ${classes.blogmore}`}>
            <Link to={`/blog/`}>ブログをもっと見る</Link>
          </div>
        </div>
      </article>
    </Layout>
  )
}
export const pageQuery = graphql`
  {
    allContentfulBlogPost(
      limit: 3
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "YYYY.MM.DD")
        }
      }
    }

    file(relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }) {
      childImageSharp {
        fluid(
          maxWidth: 600
          maxHeight: 600
          cropFocus: CENTER
          quality: 85
          traceSVG: { turnPolicy: TURNPOLICY_MINORITY, blackOnWhite: false }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    allContentfulTop {
      edges {
        node {
          post {
            ... on ContentfulDesign {
              slug
              title
              id
              heroImage {
                fluid(
                  resizingBehavior: SCALE
                  maxWidth: 600
                  maxHeight: 600
                  quality: 85
                ) {
                  ...GatsbyContentfulFluid_tracedSVG
                }
              }
              sys {
                contentType {
                  sys {
                    contentful_id
                  }
                }
              }
            }
            ... on ContentfulIllustration {
              slug
              title
              heroImage {
                fluid(
                  resizingBehavior: SCALE
                  maxWidth: 600
                  maxHeight: 600
                  quality: 85
                ) {
                  ...GatsbyContentfulFluid_tracedSVG
                }
              }

              sys {
                contentType {
                  sys {
                    contentful_id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export default Index
